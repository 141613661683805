import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  WebAppEvent,
  WebApp,
  WebAppDisk,
  WebAppEnvironment,
  WebAppJob,
  WebAppLog,
  WebAppOverview,
  WebAppPreview,
  WebAppSecretFile,
  WebAppShared,
  WebAppsMetrics,
  WebAppState,
} from "../../types/web-app";
import {
  createWebAppSharingApi,
  deleteWebAppDiskApi,
  getWebAppApi,
  getWebAppDiskApi,
  getWebAppEnvironmentsApi,
  getWebAppOverviewApi,
  getWebAppLogsApi,
  getWebAppSecretFilesApi,
  getWebAppSharedApi,
  saveWebAppDiskApi,
  saveWebAppEnvironmentsApi,
  saveWebAppSecretFilesApi,
  updateWebAppDockerCommandApi,
  updateWebAppHealthCheckPathApi,
  updateWebAppPreDeployCommandApi,
  updateWebAppApi,
  getWebAppMetricsApi,
  restartWebAppApi,
  getWebAppEventsApi,
} from "../../apis/webAppsAPI";

const dummyDisk: WebAppDisk = {
  DiskName: "test",
  DiskMountPath: "/usr/data/lib",
  DiskSize: 11,
};

const dummySecretFiles: WebAppSecretFile[] = [
  { Filename: "key_1", FileContents: "abc1234" },
];

const dummyPreviews: WebAppPreview[] = [
  {
    By: "moe@djuno.io",
    CommitId:
      "eeabfa5cd6a2091bf35eb9eae6ae48aab8231fd760f5a61cd0129df454333b1d",
    CreatedAt: "2024-03-25T14:52:13.064140228Z",
    Link: "https://djuno.io/test-54/preview",
    Type: "image",
  },
  {
    By: "moe@djuno.io",
    CommitId:
      "eeabfa5cd6a2091bf35eb9eae6ae48aab8231fd760f5a61cd0129df454333b1d",
    CreatedAt: "2024-03-25T14:52:13.064140228Z",
    Link: "https://djuno.io/test-54/preview",
    Type: "image",
  },
];

const dummyJobs: WebAppJob[] = [
  {
    Name: "job-1234",
    StartCommand: "echo hi",
    CreatedAt: "2024-03-25T14:52:13.064140228Z",
    Plan: "plan-cra-002",
    Status: "Pending",
  },
];

const dummySharing: WebAppShared[] = [
  {
    Id: 1,
    Email: "moe@djuno.io",
    Status: 0,
  },
  {
    Id: 2,
    Email: "ansar@djuno.io",
    Status: 0,
  },
];

export const WebAppsUpdatingMessage =
  "We are updating your service. Please wait...";

const initialState: WebAppState = {
  webApp: null,
  webAppLoading: false,

  //overview
  overview: null,
  overviewLoading: false,

  //disks
  disk: undefined,
  diskLoading: false,
  diskActionLoading: false,

  //logs
  // logs: {
  //   hasMore: false,
  //   logs: [],
  //   nextEndTime: null,
  //   nextStartTime: null,
  // },
  logs: [],
  logsLoading: false,

  //secret-files
  secretFiles: [],
  secretFilesLoading: false,
  secretFilesActionLoading: false,

  //previews
  previews: [],
  previewsLoading: false,

  //jobs
  jobs: [],
  jobsLoading: false,

  //sharing
  shared: [],
  sharedLoading: false,
  sharingLoading: false, //for invite
  deleteShared: null,

  //scaling
  // scaling: 1,
  // manualScaling: true,
  // scalingLoading: false,
  // scalingUpdateLoading: false,

  //settings
  updateDockerCommandLoading: false,
  updatePreDeployCommandLoading: false,
  showUpdateImageModal: false,
  updateHealthCheckPathLoading: false,

  //update web-app
  updateLoading: false,

  //metrics
  activeStatus: true,
  activeStatusMessage: null,

  metrics: null,
  metricsLoading: false,
  usage: null,

  //restart
  restartLoading: false,

  //events
  events: [],
  eventsLoading: false,
};

export const getWebAppAsync = createAsyncThunk<
  { webApp: WebApp },
  { webAppId: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "web-app",
  async (
    { webAppId, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, requestId, dispatch }
  ) => {
    try {
      dispatch(
        getWebAppAsync.pending(requestId, {
          webAppId,
          withoutLoading,
        })
      );

      const response = await getWebAppApi(webAppId);
      const webApp = response.data.Result;

      return fulfillWithValue({ webApp });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getWebAppOverviewAsync = createAsyncThunk<
  { overview: WebAppOverview },
  { webAppId: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "web-app/overview",
  async (
    { webAppId, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, requestId, dispatch }
  ) => {
    try {
      dispatch(
        getWebAppOverviewAsync.pending(requestId, {
          webAppId,
          withoutLoading,
        })
      );
      const response = await getWebAppOverviewApi(webAppId);
      const overview = response.data.Result;
      return fulfillWithValue({ overview });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateWebAppAsync = createAsyncThunk<
  boolean,
  { webAppId: string; data: any },
  IThunkRejectValue
>("web-app/update", async ({ webAppId, data }, { rejectWithValue }) => {
  try {
    await updateWebAppApi(webAppId, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getWebAppMetricsAsync = createAsyncThunk<
  {
    webAppsMetrics: WebAppsMetrics;
    // usage: number
  },
  { webAppId: string; withoutLoading?: boolean; time: number },
  IThunkRejectValue
>(
  "webApps/metrics",
  async (
    { webAppId, withoutLoading = false, time },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getWebAppMetricsAsync.pending(requestId, {
          webAppId,
          withoutLoading,
          time,
        })
      );

      const metrics_response = await getWebAppMetricsApi(webAppId, time);
      const webAppsMetrics = metrics_response.data.Result;

      // const usage_response = await getWebAppUsageApi();
      // const webAppUsage = usage_response.data.Result.Usage;

      return fulfillWithValue({
        webAppsMetrics,
        // usage: webAppUsage
      });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getWebAppLogsAsync = createAsyncThunk<
  { logs: WebAppLog[] },
  { webAppId: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "web-app/logs",
  async (
    { webAppId, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, requestId, dispatch }
  ) => {
    try {
      dispatch(
        getWebAppLogsAsync.pending(requestId, {
          webAppId,
          withoutLoading,
        })
      );

      const response = await getWebAppLogsApi(webAppId);
      const logs = response.data.Result;
      return fulfillWithValue({ logs });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppDiskAsync = createAsyncThunk<
  { disk: WebAppDisk },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/disk",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppDiskApi(webAppId);
      const disk = response.data;
      return fulfillWithValue({ disk });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const saveWebAppDiskAsync = createAsyncThunk<
  boolean,
  { webAppId: string; data: any },
  IThunkRejectValue
>("web-app/disk/save", async ({ webAppId, data }, { rejectWithValue }) => {
  try {
    await saveWebAppDiskApi(webAppId, data);
    return true;
  } catch (e) {
    return rejectWithValue({
      message: getExtractErrors(e),
    });
  }
});

export const deleteWebAppDiskAsync = createAsyncThunk<
  boolean,
  { webAppId: string },
  IThunkRejectValue
>("web-app/disk/dalete", async ({ webAppId }, { rejectWithValue }) => {
  try {
    await deleteWebAppDiskApi(webAppId);
    return true;
  } catch (e) {
    return rejectWithValue({
      message: getExtractErrors(e),
    });
  }
});

export const getWebAppEnvironmentsAsync = createAsyncThunk<
  { environments: WebAppEnvironment[] },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/environments",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppEnvironmentsApi(webAppId);
      const environments = response.data.Result;
      return fulfillWithValue({ environments });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const saveWebAppEnvironmentsAsync = createAsyncThunk<
  boolean,
  { webAppId: string; envs: WebAppEnvironment[] },
  IThunkRejectValue
>(
  "web-app/environments/save",
  async ({ webAppId, envs }, { rejectWithValue }) => {
    try {
      await saveWebAppEnvironmentsApi(webAppId, envs);
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getWebAppSecretFilesAsync = createAsyncThunk<
  { secretFiles: WebAppSecretFile[] },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/secret-files",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppSecretFilesApi(webAppId);
      const secretFiles = response.data.Result;
      return fulfillWithValue({ secretFiles });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const saveWebAppSecretFilesAsync = createAsyncThunk<
  boolean,
  { webAppId: string; secretFiles: WebAppSecretFile[] },
  IThunkRejectValue
>(
  "web-app/secret-files/save",
  async ({ webAppId, secretFiles }, { rejectWithValue }) => {
    try {
      await saveWebAppSecretFilesApi(webAppId, secretFiles);
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getWebAppPreviewsAsync = createAsyncThunk<
  { previews: WebAppPreview[] },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/previews",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppSecretFilesApi(webAppId);
      const previews = response.data.Result;
      return fulfillWithValue({ previews });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppJobsAsync = createAsyncThunk<
  { jobs: WebAppJob[] },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/jobs",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppSecretFilesApi(webAppId);
      const jobs = response.data.Result;
      return fulfillWithValue({ jobs });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppSharedAsync = createAsyncThunk<
  { shared: WebAppShared[] },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/shared",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppSharedApi(webAppId);
      const shared = response.data.Result;
      return fulfillWithValue({ shared });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createWebAppSharingAsync = createAsyncThunk<
  boolean,
  { webAppId: string; Email: string },
  IThunkRejectValue
>("web-app/sharing", async ({ webAppId, Email }, { rejectWithValue }) => {
  try {
    await createWebAppSharingApi(webAppId, { Email });
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteWebAppSharingAsync = createAsyncThunk<
  { sharedId: number },
  { webAppId: string; Id: number },
  IThunkRejectValue
>(
  "web-app/shared/delete",
  async (
    { webAppId, Id },
    { rejectWithValue, dispatch, requestId, fulfillWithValue }
  ) => {
    try {
      //send extra data to pending case
      dispatch(deleteWebAppSharingAsync.pending(requestId, { webAppId, Id }));
      // await deleteWebAppSharingApi(webAppId, Id);
      return fulfillWithValue({ sharedId: Id });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

// export const getWebAppManualScalingAsync = createAsyncThunk<
//   { value: number },
//   { webAppId: string },
//   IThunkRejectValue
// >(
//   "web-app/manual-scaling",
//   async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
//     try {
//       const res = await getWebAppManualScalingApi(webAppId);
//       const value = res.data.Result;
//       return fulfillWithValue({ value });
//     } catch (e) {
//       return rejectWithValue({ message: getExtractErrors(e) });
//     }
//   }
// );

export const updateWebAppDockerCommandAsync = createAsyncThunk<
  boolean,
  { webAppId: string; DockerCommand: string },
  IThunkRejectValue
>(
  "web-app/update/docker-command",
  async ({ webAppId, DockerCommand }, { rejectWithValue }) => {
    try {
      await updateWebAppDockerCommandApi(webAppId, { DockerCommand });
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateWebAppPreDeployCommandAsync = createAsyncThunk<
  boolean,
  { webAppId: string; PreDeployCommand: string },
  IThunkRejectValue
>(
  "web-app/update/pre-deploy-command",
  async ({ webAppId, PreDeployCommand }, { rejectWithValue }) => {
    try {
      await updateWebAppPreDeployCommandApi(webAppId, { PreDeployCommand });
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateWebAppHealthCheckPathAsync = createAsyncThunk<
  boolean,
  { webAppId: string; Path: string },
  IThunkRejectValue
>(
  "web-app/update/health-check-path",
  async ({ webAppId, Path }, { rejectWithValue }) => {
    try {
      await updateWebAppHealthCheckPathApi(webAppId, { Path });
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const restartWebAppAsync = createAsyncThunk<
  boolean,
  { webAppId: string },
  IThunkRejectValue
>("web-app/restart", async ({ webAppId }, { rejectWithValue }) => {
  try {
    await restartWebAppApi(webAppId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getWebAppEventsAsync = createAsyncThunk<
  { events: Array<WebAppEvent> },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/events",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppEventsApi(webAppId);
      const events = response.data.Result;

      return fulfillWithValue({ events });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const webAppSlice = createSlice({
  name: "webApp",
  initialState,
  reducers: {
    handleSetWebApp: (state, action: PayloadAction<WebApp | null>) => {
      state.webApp = action.payload;
    },
    handleClearWebAppSlice: (state) => {
      state.webApp = initialState.webApp;
      state.webAppLoading = initialState.webAppLoading;
      state.logs = initialState.logs;
      state.logsLoading = initialState.logsLoading;
      state.overview = initialState.overview;
      state.overviewLoading = initialState.overviewLoading;
      state.updateLoading = initialState.updateLoading;
    },
    handleShowWebAppUpdateImageModal: (state) => {
      state.showUpdateImageModal = true;
    },
    handleHideWebAppUpdateImageModal: (state) => {
      state.showUpdateImageModal = false;
    },

    changeWebAppsActiveStatus(
      state,
      action: PayloadAction<{ status: boolean; message?: string }>
    ) {
      state.activeStatus = action.payload.status;
      state.activeStatusMessage = action.payload.message || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWebAppAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.webAppLoading = true;
      })
      .addCase(getWebAppAsync.fulfilled, (state, action) => {
        state.webApp = action.payload.webApp;
        state.webAppLoading = false;
      })
      .addCase(getWebAppAsync.rejected, (state, { payload }) => {
        state.webAppLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppOverviewAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.overviewLoading = true;
      })
      .addCase(getWebAppOverviewAsync.fulfilled, (state, action) => {
        state.overview = action.payload.overview;
        state.overviewLoading = false;
      })
      .addCase(getWebAppOverviewAsync.rejected, (state, { payload }) => {
        state.overviewLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateWebAppAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateWebAppAsync.fulfilled, (state, { payload }) => {
        state.updateLoading = false;
      })
      .addCase(updateWebAppAsync.rejected, (state, { payload }) => {
        state.updateLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppDiskAsync.pending, (state, action) => {
        state.diskLoading = true;
      })
      .addCase(getWebAppDiskAsync.fulfilled, (state, action) => {
        state.disk = action.payload.disk;
        state.diskLoading = false;
      })
      .addCase(getWebAppDiskAsync.rejected, (state, { payload }) => {
        state.diskLoading = false;
        //TODO
        state.disk = dummyDisk;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(saveWebAppDiskAsync.pending, (state) => {
        state.diskActionLoading = true;
      })
      .addCase(saveWebAppDiskAsync.fulfilled, (state) => {
        state.diskActionLoading = false;
      })
      .addCase(saveWebAppDiskAsync.rejected, (state, { payload }) => {
        state.diskActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWebAppDiskAsync.pending, (state) => {
        state.diskActionLoading = true;
      })
      .addCase(deleteWebAppDiskAsync.fulfilled, (state) => {
        state.diskActionLoading = false;
        state.disk = undefined;
      })
      .addCase(deleteWebAppDiskAsync.rejected, (state, { payload }) => {
        state.diskActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppLogsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.logsLoading = true;
      })
      .addCase(getWebAppLogsAsync.fulfilled, (state, action) => {
        state.logs = action.payload.logs;
        state.logsLoading = false;
      })
      .addCase(getWebAppLogsAsync.rejected, (state, { payload }) => {
        state.logsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppSecretFilesAsync.pending, (state) => {
        state.secretFilesLoading = true;
      })
      .addCase(getWebAppSecretFilesAsync.fulfilled, (state, action) => {
        state.secretFiles = action.payload.secretFiles;
        state.secretFilesLoading = false;
      })
      .addCase(getWebAppSecretFilesAsync.rejected, (state, { payload }) => {
        state.secretFilesLoading = false;
        //TODO
        state.secretFiles = dummySecretFiles;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(saveWebAppSecretFilesAsync.pending, (state) => {
        state.secretFilesActionLoading = true;
      })
      .addCase(saveWebAppSecretFilesAsync.fulfilled, (state) => {
        state.secretFilesActionLoading = false;
      })
      .addCase(saveWebAppSecretFilesAsync.rejected, (state, { payload }) => {
        state.secretFilesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppPreviewsAsync.pending, (state) => {
        state.previewsLoading = true;
      })
      .addCase(getWebAppPreviewsAsync.fulfilled, (state, action) => {
        state.previews = action.payload.previews;
        state.previewsLoading = false;
      })
      .addCase(getWebAppPreviewsAsync.rejected, (state, { payload }) => {
        state.previewsLoading = false;
        //TODO
        state.previews = dummyPreviews;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppJobsAsync.pending, (state) => {
        state.jobsLoading = true;
      })
      .addCase(getWebAppJobsAsync.fulfilled, (state, action) => {
        state.jobs = action.payload.jobs;
        state.jobsLoading = false;
      })
      .addCase(getWebAppJobsAsync.rejected, (state, { payload }) => {
        state.jobsLoading = false;
        //TODO
        state.jobs = dummyJobs;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppSharedAsync.pending, (state) => {
        state.sharedLoading = true;
      })
      .addCase(getWebAppSharedAsync.fulfilled, (state, action) => {
        state.shared = action.payload.shared;
        state.sharedLoading = false;
      })
      .addCase(getWebAppSharedAsync.rejected, (state, { payload }) => {
        state.sharedLoading = false;
        //TODO
        state.shared = dummySharing;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createWebAppSharingAsync.pending, (state) => {
        state.sharingLoading = true;
      })
      .addCase(createWebAppSharingAsync.fulfilled, (state, { payload }) => {
        state.sharingLoading = false;
      })
      .addCase(createWebAppSharingAsync.rejected, (state, { payload }) => {
        state.sharingLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWebAppSharingAsync.pending, (state, { meta }) => {
        state.deleteShared = meta.arg.Id;
      })
      .addCase(deleteWebAppSharingAsync.fulfilled, (state, { payload }) => {
        state.deleteShared = null;
        state.shared = [
          ...state.shared.filter((i) => i.Id !== payload.sharedId),
        ];
      })
      .addCase(deleteWebAppSharingAsync.rejected, (state, { payload }) => {
        state.deleteShared = null;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    // builder
    //   .addCase(getWebAppManualScalingAsync.pending, (state) => {
    //     state.scalingLoading = true;
    //   })
    //   .addCase(getWebAppManualScalingAsync.fulfilled, (state, action) => {
    //     state.scaling = action.payload.value;
    //     state.scalingLoading = false;
    //   })
    //   .addCase(getWebAppManualScalingAsync.rejected, (state, { payload }) => {
    //     state.scalingLoading = false;
    //     if (payload?.message)
    //       toast.error(() => CustomErrorToast(payload?.message));
    //   });

    builder
      .addCase(updateWebAppDockerCommandAsync.pending, (state) => {
        state.updateDockerCommandLoading = true;
      })
      .addCase(updateWebAppDockerCommandAsync.fulfilled, (state) => {
        state.updateDockerCommandLoading = false;
      })
      .addCase(
        updateWebAppDockerCommandAsync.rejected,
        (state, { payload }) => {
          state.updateDockerCommandLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(updateWebAppPreDeployCommandAsync.pending, (state) => {
        state.updatePreDeployCommandLoading = true;
      })
      .addCase(updateWebAppPreDeployCommandAsync.fulfilled, (state) => {
        state.updatePreDeployCommandLoading = false;
      })
      .addCase(
        updateWebAppPreDeployCommandAsync.rejected,
        (state, { payload }) => {
          state.updatePreDeployCommandLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(updateWebAppHealthCheckPathAsync.pending, (state) => {
        state.updateHealthCheckPathLoading = true;
      })
      .addCase(updateWebAppHealthCheckPathAsync.fulfilled, (state) => {
        state.updateHealthCheckPathLoading = false;
      })
      .addCase(
        updateWebAppHealthCheckPathAsync.rejected,
        (state, { payload }) => {
          state.updateHealthCheckPathLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getWebAppMetricsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.metricsLoading = true;
      })
      .addCase(getWebAppMetricsAsync.fulfilled, (state, action) => {
        state.metrics = action.payload.webAppsMetrics;
        state.metricsLoading = false;
        // state.usage = action.payload.usage;
      })
      .addCase(getWebAppMetricsAsync.rejected, (state, { payload }) => {
        state.metricsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(restartWebAppAsync.pending, (state) => {
        state.restartLoading = true;
      })
      .addCase(restartWebAppAsync.fulfilled, (state, { payload }) => {
        state.restartLoading = false;
      })
      .addCase(restartWebAppAsync.rejected, (state, { payload }) => {
        state.restartLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppEventsAsync.pending, (state, action) => {
        state.eventsLoading = true;
      })
      .addCase(getWebAppEventsAsync.fulfilled, (state, action) => {
        state.events = action.payload.events;
        state.eventsLoading = false;
      })
      .addCase(getWebAppEventsAsync.rejected, (state, { payload }) => {
        state.eventsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWebApp = (state: RootState) => state.webApp.webApp;
export const selectWebAppLoading = (state: RootState) =>
  state.webApp.webAppLoading;

//overview
export const selectWebAppOverview = (state: RootState) => state.webApp.overview;
export const selectWebAppOverviewLoading = (state: RootState) =>
  state.webApp.overviewLoading;

//logs
export const selectWebAppLogs = (state: RootState) => state.webApp.logs;
export const selectWebAppLogsLoading = (state: RootState) =>
  state.webApp.logsLoading;

//disk
export const selectWebAppDisk = (state: RootState) => state.webApp.disk;
export const selectWebAppDiskLoading = (state: RootState) =>
  state.webApp.diskLoading;
export const selectWebAppDiskActionLoading = (state: RootState) =>
  state.webApp.diskActionLoading;

//secret-files
export const selectWebAppSecretFiles = (state: RootState) =>
  state.webApp.secretFiles;
export const selectWebAppSecretFilesLoading = (state: RootState) =>
  state.webApp.secretFilesLoading;
export const selectWebAppSecretFilesActionLoading = (state: RootState) =>
  state.webApp.secretFilesActionLoading;

//previews
export const selectWebAppPreviews = (state: RootState) => state.webApp.previews;
export const selectWebAppPreviewsLoading = (state: RootState) =>
  state.webApp.previewsLoading;

//previews
export const selectWebAppJobs = (state: RootState) => state.webApp.jobs;
export const selectWebAppJobsLoading = (state: RootState) =>
  state.webApp.jobsLoading;

//sharing
export const selectWebAppShared = (state: RootState) => state.webApp.shared;
export const selectWebAppSharedLoading = (state: RootState) =>
  state.webApp.sharedLoading;
export const selectWebAppSharingLoading = (state: RootState) =>
  state.webApp.sharingLoading;
export const selectWebAppDeleteId = (state: RootState) =>
  state.webApp.deleteShared;

//sharing
// export const selectWebAppScaling = (state: RootState) => state.webApp.scaling;
// export const selectWebAppScalingLoading = (state: RootState) =>
//   state.webApp.scalingLoading;
// export const selectWebAppScalingUpdateLoading = (state: RootState) =>
//   state.webApp.scalingUpdateLoading;
// export const selectWebAppManualScaling = (state: RootState) =>
//   state.webApp.manualScaling;

//settings
export const selectWebAppUpdateDockerCommandLoading = (state: RootState) =>
  state.webApp.updateDockerCommandLoading;
export const selectWebAppUpdatePreDeployCommandLoading = (state: RootState) =>
  state.webApp.updatePreDeployCommandLoading;
export const selectWebAppShowUpdateImageModal = (state: RootState) =>
  state.webApp.showUpdateImageModal;
export const selectWebAppUpdateHealthCheckPathLoading = (state: RootState) =>
  state.webApp.updateHealthCheckPathLoading;

export const selectWebAppUpdateLoading = (state: RootState) =>
  state.webApp.updateLoading;

//metrics
export const selectWebAppsMetrics = (state: RootState) => state.webApp.metrics;
export const selectWebAppsMetricsLoading = (state: RootState) =>
  state.webApp.metricsLoading;
export const selectWebAppsUsage = (state: RootState) => state.webApp.usage;

//restart
export const selectWebAppRestartLoading = (state: RootState) =>
  state.webApp.restartLoading;

//events
//metrics
export const selectWebAppEvents = (state: RootState) => state.webApp.events;
export const selectWebAppEventsLoading = (state: RootState) =>
  state.webApp.eventsLoading;

export const {
  handleSetWebApp,
  handleClearWebAppSlice,
  handleShowWebAppUpdateImageModal,
  handleHideWebAppUpdateImageModal,
  changeWebAppsActiveStatus,
} = webAppSlice.actions;
export default webAppSlice.reducer;
