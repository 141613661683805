import {
  Button,
  cn,
  EmptyState,
  Flex,
  Input,
  Loading,
  Tooltip,
  Typography,
} from "djuno-design";
import { ReactComponent as SearchIcon } from "./../../assets/icons/search.svg";
import { ReactComponent as GlobIcon } from "./../../assets/icons/glob-alt.svg";
import { ReactComponent as CheckIcon } from "./../../assets/icons/check.svg";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  searchUnstoppableDomainsAysnc,
  selectSearchedUnstoppableDomains,
  selectUnstoppableDomainsSearchLoading,
} from "../../store/unstoppable-domains/unstoppableDomainsSlice";

const UDomainsSearchTab = () => {
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const serchedDomains = useAppSelector(selectSearchedUnstoppableDomains);
  const serchLoading = useAppSelector(selectUnstoppableDomainsSearchLoading);

  const dispatch = useAppDispatch();

  const handleSearch = () => {
    setSearch(searchInputValue);
  };

  useEffect(() => {
    if (search) {
      dispatch(searchUnstoppableDomainsAysnc({ query: search }));
    }
  }, [dispatch, search]);

  return (
    <Flex direction="col" className="max-w-lg mx-auto mt-10">
      <div className="flex transition-all duration-500 w-full">
        <Input
          containerClassName="w-full"
          className="flex-1"
          onChange={(e: any) => setSearchInputValue(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.code === "Enter") handleSearch();
          }}
          placeholder="Search for your new domain"
          AfterComponent={
            <button
              onClick={handleSearch}
              className="w-10 flex items-center justify-center dark:text-white border-l border-solid border-transparent rounded-r-xl bg-primary-400 text-white"
            >
              <SearchIcon className="w-4" />
            </button>
          }
        />
      </div>
      <div className="mt-6">
        {serchLoading && (
          <Flex items="center" justify="center" className="min-h-[300px]">
            <Loading borderSize={2} />
          </Flex>
        )}

        {!serchLoading && serchedDomains.length === 0 && (
          <EmptyState
            text="Find your perfect domain by typing into the search field above."
            icon={<SearchIcon className="w-8" />}
            className="min-h-[300px]"
          />
        )}

        {!serchLoading && serchedDomains.length > 0 && (
          <ol className="border border-gray-200 dark:border-gray-700 rounded-lg">
            {serchedDomains.map((domain, i) => (
              <li className="grid grid-cols-12 items-center px-4 py-3 border-b border-gray-200 dark:border-gray-700 last:border-0">
                <div
                  className={cn("col-span-1 flex items-center justify-center")}
                >
                  <CheckIcon className="text-green-500 w-5 h-5" />
                </div>
                <div className="col-span-1 flex items-center justify-center">
                  <Tooltip
                    theme="black"
                    className="!text-xs"
                    content="Web2 domains are compatible with most Internet services and infrastructure"
                  >
                    <GlobIcon className="w-5 h-6" />
                  </Tooltip>
                </div>
                <div className="col-span-7">
                  <Typography.Text size="sm">test.eth</Typography.Text>
                </div>
                <div className="col-span-3">
                  <Flex className="flex w-full">
                    <Button className="!flex-1 !justify-center">Buy</Button>
                  </Flex>
                </div>
              </li>
            ))}
          </ol>
        )}
      </div>
    </Flex>
  );
};

export default UDomainsSearchTab;
