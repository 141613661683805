import { Button, cn, Flex, Loading, Typography } from "djuno-design";
import { Helmet } from "react-helmet";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { WorkflowsUrl, WorkflowUrl } from "../../utils/urls";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";

import {
  getWorkflowsTemplatesAsync,
  selectWrokflowsTemplates,
  selectWrokflowsTemplatesLoading,
} from "../../store/workflows/workflowsSlice";
import { WorkflowsTemplate } from "../../types/workflows";

const WorkflowTemplatePage = () => {
  const templates = useAppSelector(selectWrokflowsTemplates) || [];
  const templatesLoading = useAppSelector(selectWrokflowsTemplatesLoading);
  const [selectedTemplate, setSelectedTemplate] = useState<
    WorkflowsTemplate | undefined
  >();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getWorkflowsTemplatesAsync());
  }, [dispatch]);

  const handleSelectTemplate = (template: WorkflowsTemplate) => {
    setSelectedTemplate(template);
    navigate(WorkflowUrl(), {
      state: { selectedTemplate: template },
    });
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Templates</title>
        <meta
          name="description"
          content="RPC-endpoints, or Remote Procedure Call endpoints, are a fundamental aspect of distributed computing systems, enabling communication between different components or services across a network"
        />
      </Helmet>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 bg-white dark:bg-dark-1">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium flex items-center gap-1">
            <Typography.Text>Templates</Typography.Text>
          </div>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(WorkflowsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <div className="mt-5 w-full overflow-x-auto px-6 min-h-[calc(100%-6rem)]">
        <div className="mx-auto flex-1 flex justify-center pb-20">
          <AnimatePresence>
            <>
              {!templatesLoading && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="max-w-4xl px-1 pb-12 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 md:gap-4"
                >
                  {!templatesLoading &&
                    Array.isArray(templates) &&
                    templates.map((template: WorkflowsTemplate, index: any) => (
                      <WorkflowTemplateCard
                        key={index}
                        {...template}
                        selected={selectedTemplate?.Id === template.Id}
                        onClick={() => handleSelectTemplate(template)}
                      />
                    ))}
                </motion.div>
              )}
              {templatesLoading && (
                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="min-h-[300px] w-full flex justify-center items-center"
                >
                  <Loading borderSize={2} />
                </motion.div>
              )}
            </>
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export const WorkflowTemplateCard = ({
  TemplateDesctiption,
  TemplateImage,
  TemplateName,
  selected,
  onClick,
}: WorkflowsTemplate & {
  selected?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      className={cn(
        "border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 w-64",
        {
          "border-primary-400 dark:border-primary-400": selected,
          "cursor-pointer": onClick,
        }
      )}
      onClick={() => (onClick ? onClick() : {})}
    >
      <Flex direction="col" items="center" className="text-center">
        <Flex
          items="center"
          justify="center"
          className="w-[100px] h-[100px] p-2"
        >
          <img className="h-full" alt={""} src={TemplateImage} />
        </Flex>
        <Flex direction="col" className="gap-1">
          <Typography.Text size="sm" className="font-semibold">
            {TemplateName}
          </Typography.Text>
          <Typography.Text size="sm" uiType="secondary">
            {TemplateDesctiption}
          </Typography.Text>
        </Flex>
      </Flex>
    </div>
  );
};

export default WorkflowTemplatePage;
