import React, { useCallback, useEffect, useMemo, useState } from "react";
import socketIOClient from "socket.io-client"; // Import your Redux action
import {
  addNotification,
  markAsReadNotificationAsync,
  selectNotificationsAudioAllowed,
} from "../../store/notifications/notificationsSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectUser } from "../../store/auth/authSlice";
import {
  Notification,
  SocketNotificationBody,
} from "../../types/notifications";
import toast, { Toast } from "react-hot-toast";
import { Button, cn, Flex, Typography } from "djuno-design";
import { ReactComponent as BellIcon } from "./../../assets/icons/bell-alert.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as CheckIcon } from "./../../assets/icons/keep_both.svg";
import { Link } from "react-router-dom";

const NotificationSocket: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [docIsAllowed, setDocIsAllowed] = useState(false);
  const audio = useMemo(() => new Audio("/notif-sound.mp3"), []);
  const audioAllowed = useAppSelector(selectNotificationsAudioAllowed);

  const soundedNotifs = useMemo(() => {
    return docIsAllowed && audioAllowed;
  }, [audioAllowed, docIsAllowed]);

  useEffect(() => {
    // Create a socket connection to the server
    const socket = socketIOClient("", {
      path: "/app-notif",
      auth: {
        userId: user?.Id,
      },
      withCredentials: true,
      transports: ["websocket", "polling"],
    });

    // Listen for new notifications from the socket server
    socket.on("notification", (message: SocketNotificationBody) => {
      // console.log("new notification", message);
      const { notification, alert = true, action, data } = message;

      if (alert) {
        if (soundedNotifs) {
          audio.play();
          setTimeout(() => {
            audio.pause();
            audio.currentTime = 0;
          }, 300);
        }
        toast.custom(
          (t) => <NotificationToastItem t={t} notification={notification} />,
          {
            position: "top-right",
          }
        );
      }

      dispatch(addNotification(notification));

      //TODO: use action and data to reload or update store
    });

    return () => {
      socket.disconnect();
    };
  }, [audio, soundedNotifs, dispatch, user?.Id]);

  useEffect(() => {
    document.addEventListener("click", () => {
      setDocIsAllowed(true);
    });
    return () => {
      document.removeEventListener("click", () => {});
    };
  });
  return null;
};

export const NotificationToastItem: React.FC<{
  t?: Toast;
  notification: Notification;
}> = ({ t, notification }) => {
  // console.log({ t });
  const [isRead, setIsRead] = useState(notification.IsRead);
  const [markLoading, setMarkLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleMardAsRead = useCallback(
    (notifId: number) => {
      if (!markLoading) {
        setMarkLoading(true);
        dispatch(markAsReadNotificationAsync({ notificationId: notifId })).then(
          (action) => {
            if (action.type === "notifications/mark-as-read/fulfilled") {
              setIsRead(true);
            }
            setMarkLoading(false);
          }
        );
      }
    },
    [dispatch, markLoading]
  );

  return (
    <div
      className={cn(
        "w-full bg-white dark:bg-dark-2  rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5",
        {
          "shadow-lg max-w-sm ": t,
          "animate-leave": t && !t.visible,
          "animate-enter": t && t.visible,
        }
      )}
    >
      <Flex direction="col" className="flex-1 p-3">
        {t && (
          <Flex items="center" justify="between" className="w-full">
            <BellIcon className="w-5 h-5 text-slate-900 dark:text-slate-100" />
            <Button
              uiType="icon"
              onClick={() => toast.dismiss(t.id)}
              className="!group !p-2.5 !top-3"
            >
              <CloseIcon className="w-4 h-4 group-hover:scale-110 duration-300 transition-all text-slate-700 dark:text-slate-300  group-hover:text-slate-900 dark:group-hover:text-slate-100" />
            </Button>
          </Flex>
        )}

        <Flex direction="col" className="flex-1">
          <Typography.Text size="sm">{notification.Content}</Typography.Text>
          <Flex items="center" className="gap-1 mt-3">
            {notification.Link && (
              <Link to={notification.Link}>
                <Button uiSize="small" uiType="light">
                  view
                </Button>
              </Link>
            )}
            {!isRead && (
              <Button
                uiSize="small"
                uiType="icon"
                className="group"
                onClick={() => handleMardAsRead(notification.Id)}
                loading={markLoading}
              >
                <Typography.Text
                  size="xs"
                  className="!text-primary-400 group-hover:!text-primary-500"
                >
                  mark as read
                </Typography.Text>
              </Button>
            )}
            {isRead && (
              <Typography.Text
                size="xs"
                className="!text-primary-500 flex items-center gap-0.5"
              >
                <CheckIcon className="w-4 h-4" />
                readed
              </Typography.Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};
export default NotificationSocket;
