import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  UnstoppableDomainsStateType,
  UnstoppableDomain,
} from "../../types/unstoppable-domains";
import {
  getUnstoppableDomainsApi,
  searchUnstoppableDomainsApi,
} from "../../apis/unstoppableDonainsAPI";

const initialState: UnstoppableDomainsStateType = {
  loading: false,
  domains: [],
  actionLoading: false,

  searchDomains: [],
  searchLoading: false,
};

export const getUnstoppableDomainsAysnc = createAsyncThunk<
  {
    domains: Array<UnstoppableDomain>;
  },
  undefined,
  IThunkRejectValue
>("unstoppable-domains", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getUnstoppableDomainsApi();
    const domains = response.data.Result;
    return fulfillWithValue({ domains });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const searchUnstoppableDomainsAysnc = createAsyncThunk<
  {
    domains: Array<UnstoppableDomain>;
  },
  { query: string },
  IThunkRejectValue
>(
  "unstoppable-domains/search",
  async ({ query }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await searchUnstoppableDomainsApi(query);
      const domains = response.data.Result;
      return fulfillWithValue({ domains });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const unstoppableDomainsSlice = createSlice({
  name: "unstoppableDomains",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUnstoppableDomainsAysnc.fulfilled, (state, action) => {
        state.domains = action.payload.domains;
        state.loading = false;
      })
      .addCase(getUnstoppableDomainsAysnc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnstoppableDomainsAysnc.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(searchUnstoppableDomainsAysnc.fulfilled, (state, action) => {
        state.searchDomains = action.payload.domains;
        state.searchLoading = false;
      })
      .addCase(searchUnstoppableDomainsAysnc.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(searchUnstoppableDomainsAysnc.rejected, (state, { payload }) => {
        state.searchDomains = [
          {
            Name: "",
            Expiry: "",
            ForSale: true,
            Id: 1,
            Purchase: "",
            Tags: [],
            Wallet: "",
          },
          {
            Name: "",
            Expiry: "",
            ForSale: true,
            Id: 1,
            Purchase: "",
            Tags: [],
            Wallet: "",
          },
        ];
        state.searchLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectUnstoppableDomains = (state: RootState) =>
  state.unstoppableDomains.domains;
export const selectUnstoppableDomainsLoading = (state: RootState) =>
  state.unstoppableDomains.loading;

export const selectSearchedUnstoppableDomains = (state: RootState) =>
  state.unstoppableDomains.searchDomains;
export const selectUnstoppableDomainsSearchLoading = (state: RootState) =>
  state.unstoppableDomains.searchLoading;

// export const {} = unstoppableDomainsSlice.actions;

export default unstoppableDomainsSlice.reducer;
