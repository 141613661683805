import { useNavigate } from "react-router-dom";
import { IconBtnWrapper } from "../../../general/Wrappers";
import { ReactComponent as ArrowRightIcon } from "./../../../../assets/icons/arrow-right.svg";
import { ReactComponent as CheckIcon } from "./../../../../assets/icons/check.svg";
import { ReactComponent as CloseIcon } from "./../../../../assets/icons/close.svg";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";
import { ReactComponent as RunIcon } from "./../../../../assets/icons/play.svg";
import { ReactComponent as StopIcon } from "./../../../../assets/icons/stop.svg";
import { ReactComponent as DriverIcon } from "./../../../../assets/icons/driver.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as SettingIcon } from "./../../../../assets/icons/setting.svg";
import { ReactComponent as LoadIcon } from "./../../../../assets/icons/arrow-right-end-on-rectangle.svg";
import { ReactComponent as ExportIcon } from "./../../../../assets/icons/arrow-right-start-on-rectangle.svg";
import { ReactComponent as ListBulletIcon } from "./../../../../assets/icons/list-bullet.svg";
import { WorkflowsUrl } from "../../../../utils/urls";
import { IFlowData, IWorkflowResponse } from "../../../../types/workflows";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectCanvas } from "../../../../store/workflows/workflowSlice";
import {
  selectWorkflowsActionLoading,
  selectWorkflowsDeployLoading,
  updateWorkflowAsync,
} from "../../../../store/workflows/workflowsSlice";
import { generateExportFlowData } from "../../../../utils/wfHelper";
import { AnimatePresence, motion } from "framer-motion";
import Executions from "../executions";
import SaveWorkflowDialog from "../dialog/SaveWorkflowDialog";
import {
  Dropdown,
  Loading,
  Tag,
  Typography,
  Tooltip,
  Popover,
  Button,
  Input,
} from "djuno-design";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WorkflowNameSchema } from "../../../../utils/validations";

interface ICanvasHeader {
  workflow: IWorkflowResponse | null;
  handleLoadWorkflow: (file: any) => void;
  handleSaveFlow: (workflowName: string) => void;
  handleStopWorkflow: () => void;
  handleDeployWorkflow: () => void;
  handleDeleteWorkflow: () => void;
  loading?: boolean;
  workflowDefaultName?: string;
}

const CanvasHeader: React.FC<ICanvasHeader> = ({
  workflow,
  handleLoadWorkflow,
  handleSaveFlow,
  handleStopWorkflow,
  handleDeployWorkflow,
  handleDeleteWorkflow,
  loading,
  workflowDefaultName,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isEditingWorkflowName, setEditingWorkflowName] =
    useState<boolean>(false);
  const [workflowName, setWorkflowName] = useState<string>("");
  const [workfowDialogOpen, setWorkfowDialogOpen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const canvas = useAppSelector(selectCanvas);
  const deployLoading = useAppSelector(selectWorkflowsDeployLoading);
  const actionLoading = useAppSelector(selectWorkflowsActionLoading);

  const handleSelectedFile = (e: any) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      handleLoadWorkflow(result);
    };
    reader.readAsText(file);
  };

  const onSaveWorkflowClick = () => {
    if (workflow && workflow.shortId) handleSaveFlow(workflow.name);
    else setWorkfowDialogOpen(true);
  };

  const submitWorkflowName = () => {
    if (workflow && workflow.shortId) {
      const updateBody = {
        name: workflowName,
      };
      dispatch(
        updateWorkflowAsync({ id: workflow.shortId, data: updateBody })
      ).then((action) => {
        if (action.type === "workflows/update/fulfilled") {
          setEditingWorkflowName(false);
        }
      });
    }
  };

  const onConfirmSaveName = (workflowName: string) => {
    setWorkfowDialogOpen(false);
    handleSaveFlow(workflowName);
  };

  const handleExport = () => {
    try {
      if (workflow) {
        const flowData = JSON.parse(workflow.flowData) as IFlowData;
        let dataStr = JSON.stringify(generateExportFlowData(flowData));
        let dataUri =
          "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);
        let exportFileDefaultName = `${workflow.name} Workflow.json`;
        let linkElement = document.createElement("a");
        linkElement.setAttribute("href", dataUri);
        linkElement.setAttribute("download", exportFileDefaultName);
        linkElement.click();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (workflow) {
      setWorkflowName(workflow.name);
    } else if (workflowDefaultName) {
      setWorkflowName(workflowDefaultName);
    } else {
      setWorkflowName("Untitled workflow");
    }
  }, [workflow, workflowDefaultName]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(WorkflowNameSchema),
  });

  return (
    <div className="border-b border-slate-200 dark:border-secondary-500 w-full h-full flex items-center px-3">
      <IconBtnWrapper onClick={() => navigate(WorkflowsUrl)}>
        <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 rotate-180" />
      </IconBtnWrapper>
      <div className="flex-grow ml-3">
        <div className="flex items-center gap-2">
          {!isEditingWorkflowName && (
            <div className="flex items-center gap-2">
              <Typography.Text className="!text-lg !font-medium max-w-[160px] md:max-w-[450px] lg:max-w-[350px] xl:max-w-[400px] truncate">
                {canvas.isDirty && <strong className="text-error">*</strong>}{" "}
                {workflowName}
              </Typography.Text>

              {workflow?.shortId && (
                <EditIcon
                  className="w-4 h-4 dark:text-slate-100 text-slate-800 hover:cursor-pointer hover:scale-105 duration-300 hover:text-primary-500"
                  onClick={() => setEditingWorkflowName(true)}
                />
              )}

              {workflow?.deployed && <Tag color="success">Deployed</Tag>}
            </div>
          )}
          {isEditingWorkflowName && (
            <div className="flex items-center gap-1">
              <Input
                label=""
                {...register("name")}
                error={errors.name?.message}
                type="text"
                value={workflowName}
                onChange={(e) => setWorkflowName(e.target.value)}
              />
              <IconBtnWrapper onClick={handleSubmit(submitWorkflowName)}>
                <CheckIcon className="w-4 h-4 text-slate-800 dark:text-slate-200" />
              </IconBtnWrapper>
              <IconBtnWrapper
                type="danger"
                onClick={() => {
                  setWorkflowName(
                    workflow ? workflow.name : '"Untitled workflow"'
                  );
                  setEditingWorkflowName(false);
                }}
              >
                <CloseIcon className="w-4 h-4 text-slate-800 dark:text-slate-200" />
              </IconBtnWrapper>
            </div>
          )}
          <AnimatePresence>
            {loading && (
              <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="w-10 aspect-square flex justify-center items-center"
              >
                <Loading borderSize={2} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="flex items-center gap-2">
        {workflow && (
          <div>
            <Popover
              content={
                <Executions
                  workflowShortId={workflow?.shortId}
                  execution={workflow?.execution}
                  executionCount={workflow?.executionCount}
                />
              }
            >
              <Button
                title={"View Execution"}
                // loadingSize={16}
                className="!px-2 !text-slate-500 hover:!text-slate-900 dark:!text-slate-100"
              >
                <Typography.Text className="!text-sm">
                  {workflow?.executionCount || "0"}
                </Typography.Text>
                <ListBulletIcon className="w-4 aspect-square" />
              </Button>
            </Popover>
          </div>
        )}

        {workflow && (
          <Tooltip content={workflow?.deployed ? "Stop" : "Deploy"}>
            <Button
              title={workflow?.deployed ? "Stop Workflow" : "Deploy Workflow"}
              onClick={
                workflow?.deployed ? handleStopWorkflow : handleDeployWorkflow
              }
              loading={deployLoading || actionLoading}
              className="!px-2 !text-slate-500 hover:!text-slate-900 dark:!text-slate-100"
            >
              {workflow?.deployed ? (
                <StopIcon className="w-4 aspect-square" />
              ) : (
                <RunIcon className="w-4 aspect-square" />
              )}
            </Button>
          </Tooltip>
        )}

        <Tooltip content="Save">
          <Button
            onClick={onSaveWorkflowClick}
            loading={actionLoading}
            // loadingSize={16}
            className="!px-2 !text-slate-500 hover:!text-slate-900 dark:!text-slate-100"
            // uiType=""
          >
            <DriverIcon className="w-4 aspect-square" />
          </Button>
        </Tooltip>

        <Dropdown
          itemsClassName="!w-40"
          anchor="bottom end"
          menu={[
            {
              key: "LoadWorkflow",
              label: (
                <div className="flex items-center gap-1">
                  <LoadIcon className="w-4" />
                  Load Workflow
                </div>
              ),
              onClick: () => fileInputRef.current?.click(),
            },
            {
              key: "ExportWorkflow",
              label: (
                <div className="flex items-center gap-1">
                  <ExportIcon className="w-4" />
                  Export Workflow
                </div>
              ),
              onClick: (_, close) => {
                close();
                handleExport();
              },
            },
            {
              type: "divider",
            },
            {
              key: "Delete",
              label: (
                <div className="flex items-center gap-1">
                  <ArchiveIcon className="w-4" />
                  Delete
                </div>
              ),
              danger: true,
              onClick: (_, close) => {
                close();
                handleDeleteWorkflow();
              },
            },
          ]}
        >
          <Button className="!px-2 !text-slate-500 hover:!text-slate-900 dark:!text-slate-100">
            <SettingIcon className="w-4 h-4" />
          </Button>
        </Dropdown>
        <input
          type="file"
          hidden
          accept=".json"
          onChange={(e) => handleSelectedFile(e)}
          ref={fileInputRef}
        />
      </div>

      <SaveWorkflowDialog
        show={workfowDialogOpen}
        name={workflowDefaultName}
        dialogProps={{
          title: `Save New Workflow`,
          confirmButtonName: "Save",
          cancelButtonName: "Cancel",
        }}
        onCancel={() => setWorkfowDialogOpen(false)}
        onConfirm={onConfirmSaveName}
      />
    </div>
  );
};
export default CanvasHeader;
