import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { cn, EmptyState, Flex, Loading, Typography } from "djuno-design";
import {
  getWebAppEventsAsync,
  selectWebApp,
  selectWebAppEvents,
  selectWebAppEventsLoading,
} from "../../../store/web-app/webAppSlice";
import { WebAppEvent } from "../../../types/web-app";
import { formatTimestampEvent } from "../../../utils/date";

const WebAppEventsTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const events = useAppSelector(selectWebAppEvents);
  const eventsLoading = useAppSelector(selectWebAppEventsLoading);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppEventsAsync({ webAppId: webApp?.Id.toString() }));
    }
  }, [dispatch]);

  return (
    <>
      <div className="flex transition-all duration-500">
        <Typography.Text className="whitespace-nowrap font-medium mt-1.5 !mb-0 !text-sm">
          {webApp?.ImageName}
        </Typography.Text>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2"></div>
      </div>

      {eventsLoading && (
        <div className="mt-5 w-full bg-white dark:bg-dark-3 border dark:border-dark-2 rounded-lg p-6 text-slate-800 dark:text-slate-200">
          {events.length > 0 && !eventsLoading && (
            <ol className="relative border-s border-gray-200 dark:border-gray-700">
              {events.map((event, i) => (
                <EventItem
                  key={i}
                  EventTime={event.EventTime}
                  FirstTimestamp={event.FirstTimestamp}
                  LastTimestamp={event.LastTimestamp}
                  InvolvedObject={event.InvolvedObject}
                  Message={event.Message}
                  Reason={event.Reason}
                  Type={event.Type}
                />
              ))}
            </ol>
          )}
          <Flex
            items="center"
            justify="center"
            className="w-full !min-h-[300px]"
          >
            <Loading borderSize={2} />
          </Flex>
          {!eventsLoading && events.length === 0 && <EmptyState />}
        </div>
      )}
    </>
  );
};

const EventItem = ({
  EventTime,
  FirstTimestamp,
  InvolvedObject,
  LastTimestamp,
  Message,
  Reason,
  Type,
}: WebAppEvent) => {
  return (
    <li className="mb-10 ms-6 last:mb-0 relative">
      <span
        className={cn(
          "absolute flex items-center justify-center w-2 h-2 rounded-full -left-7 top-1 ring-4",
          {
            "bg-primary-300 ring-primary-50 dark:ring-primary-500/10 dark:bg-primary-400":
              Type === undefined || Type === "Normal",
            "bg-orange-400 ring-orange-50 dark:ring-orange-500/10 dark:bg-orange-400":
              Type === "Warning",
          }
        )}
      />
      <div className="flex flex-col gap-1">
        {/* Reason */}
        <div className="flex items-start justify-between">
          <div className="flex items-center gap-1">
            <Typography.Title level={6} className="!text-sm !mb-0">
              {InvolvedObject.Kind}
            </Typography.Title>
            <Typography.Text className="!text-sm">
              {Reason || "No Reason Provided"}
            </Typography.Text>
          </div>
        </div>

        {/* Involved Object */}
        {/* <div className="flex items-center gap-1">
          <Typography.Title level={6} className="!text-sm !mb-0">
            Involved Object:
          </Typography.Title>
          <Typography.Text className="!text-sm">
            {InvolvedObject?.Kind} - {InvolvedObject?.Name || "Unknown Name"}
          </Typography.Text>
        </div> */}

        {/* First Timestamp */}
        {FirstTimestamp && (
          <div className="flex items-start gap-1">
            <Typography.Text className="!text-sm">
              {formatTimestampEvent(FirstTimestamp)} {Type}
            </Typography.Text>
          </div>
        )}

        {/* Message */}
        {Message && (
          <div className="flex items-start gap-1">
            <Typography.Text className="!text-xs">{Message}</Typography.Text>
          </div>
        )}
      </div>
    </li>
  );
};

export default WebAppEventsTab;
