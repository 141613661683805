import { configureStore } from "@reduxjs/toolkit";
import publicReducer from "./publicSlice";

import authReducer from "./auth/authSlice";
import surveyReducer from "./survey/surveySlice";
import environmentsReducer from "./environments/environmentsSlice";
import teamsReducer from "./settings/teams/teamsSlice";
import registeriesReducer from "./settings/registeries/registeriesSlice";
import rpcsReducer from "./rpc/rpcsSlice";
import rpcReducer from "./rpc/rpcSlice";
import servicesReducer from "./rpc/servicesSlice";
import ipfsPublicReducer from "./ipfs/ipfsPublicSlice";
import ipfsFilesReducer from "./ipfs/filesSlice";
import ipfsApiKeysReducer from "./ipfs/apiKeysSlice";
import ipfsGatewaysReducer from "./ipfs/gatewaysSlice";
import billingReducer from "./billing/billingSlice";
import s3PublicReducer from "./s3/s3PublicSlice";
import s3overviewReducer from "./s3/overviewSlice";
import s3BucketsReducer from "./s3/buckets/bucketsSlice";
import s3BucketReducer from "./s3/buckets/bucketSlice";
import s3ObjectReducer from "./s3/buckets/objectSlice";
import s3AnonymousReducer from "./s3/buckets/setting/anonymousSlice";
import s3LifecycleReducer from "./s3/buckets/setting/lifecycleSlice";
import s3AccessKeysReducer from "./s3/accessKeysSlice";
import s3EventsReducer from "./s3/eventsSlice";
import s3LogsReducer from "./s3/logsSlice";
import s3SettingsReducer from "./s3/settingsSlice";
import s3TiersReducer from "./s3/tiersSlice";
import blockEventsReducer from "./block-event/blockEventsSlice";
import blockEventCreateReducer from "./block-event/blockEventCreateSlice";
import s3ReplicationReducer from "./s3/buckets/setting/replicationsSlice";
import web3AuthEndpointsReducer from "./web3-auth/web3AuthEndpointsSlice";
import web3AuthEndpointReducer from "./web3-auth/web3AuthEndpointSlice";
import web3PublicReducer from "./web3-auth/web3PublicSlice";
import blockEventDestinationsReducer from "./block-event/destinationsSlice";
import repositoriesReducer from "./settings/registeries/repositoriesSlice";
import envVariablesReducer from "./settings/env-variables/envVariablesSlice";
import webAppsReducer from "./web-app/webAppsSlice";
import webAppCreateReducer from "./web-app/webAppCreateSlice";
import webAppReducer from "./web-app/webAppSlice";
import marketApiApiKeysReducer from "./market-api/apiKeysSlice";
import marketApiOverviewReducer from "./market-api/overviewSlice";
import walletApiApiKeysReducer from "./wallet-api/apiKeysSlice";
import walletApiOverviewReducer from "./wallet-api/overviewSlice";
import blockApiApiKeysReducer from "./block-api/apiKeysSlice";
import blockApiOverviewReducer from "./block-api/overviewSlice";
import nftApiApiKeysReducer from "./nft-api/apiKeysSlice";
import nftApiOverviewReducer from "./nft-api/overviewSlice";
import databaseServicesReducer from "./database/servicesSlice";
import databaseServiceReducer from "./database/serviceSlice";
import workflowsReducer from "./workflows/workflowsSlice";
import workflowReducer from "./workflows/workflowSlice";
import workflowWalletsReducer from "./workflows/walletsSlice";
import workflowsContractsReducer from "./workflows/contractsSlice";
import workflowsApiKeysReducer from "./workflows/apiKeysSlice";
import instancesReducer from "./instances/instancesSlice";
import instanceReducer from "./instances/instanceSlice";
import instancesSshKeysReducer from "./instances/instancesSSHkeysSlice";
import instancesVolumesReducer from "./instances/instancesVolumesSlice";
import instancesVolumeReducer from "./instances/instancesVolumeSlice";
import notificationsReducer from "./notifications/notificationsSlice";
import unstoppableDomainsReducer from "./unstoppable-domains/unstoppableDomainsSlice";

export const store = configureStore({
  devTools: true,
  reducer: {
    public: publicReducer,

    //
    auth: authReducer,
    survey: surveyReducer,
    environments: environmentsReducer,

    // Settings
    billing: billingReducer,
    teams: teamsReducer,
    registeries: registeriesReducer,
    repositories: repositoriesReducer,
    envVariables: envVariablesReducer,

    //RPC endpoints
    rpcs: rpcsReducer,
    rpc: rpcReducer,
    services: servicesReducer,

    // IPFS
    ipfsPublic: ipfsPublicReducer,
    ipfsFiles: ipfsFilesReducer,
    ipfsApiKeys: ipfsApiKeysReducer,
    ipfsGateways: ipfsGatewaysReducer,

    // S3
    s3Overview: s3overviewReducer,
    s3Public: s3PublicReducer,
    buckets: s3BucketsReducer,
    s3Bucket: s3BucketReducer,
    s3Object: s3ObjectReducer,
    s3Anonymous: s3AnonymousReducer,
    s3Lifecycle: s3LifecycleReducer,
    s3AccessKeys: s3AccessKeysReducer,
    s3Events: s3EventsReducer,
    s3Logs: s3LogsReducer,
    s3Networks: s3SettingsReducer,
    s3Tiers: s3TiersReducer,
    s3Replication: s3ReplicationReducer,

    // Block Events
    blockEvents: blockEventsReducer,
    blockEventCreate: blockEventCreateReducer,
    blockEventDestinations: blockEventDestinationsReducer,

    // Web3 Auth
    web3AuthEndpoints: web3AuthEndpointsReducer,
    web3AuthEndpoint: web3AuthEndpointReducer,
    web3Public: web3PublicReducer,

    // Web Apps
    webApps: webAppsReducer,
    webAppCreate: webAppCreateReducer,
    webApp: webAppReducer,

    // Market API
    marketApiApiKeys: marketApiApiKeysReducer,
    marketApiApiOverview: marketApiOverviewReducer,

    // Wallet API
    walletApiApiKeys: walletApiApiKeysReducer,
    walletApiApiOverview: walletApiOverviewReducer,

    // Block API
    blockApiApiKeys: blockApiApiKeysReducer,
    blockApiApiOverview: blockApiOverviewReducer,

    // Nft API
    nftApiApiKeys: nftApiApiKeysReducer,
    nftApiApiOverview: nftApiOverviewReducer,

    // Database
    databaseServices: databaseServicesReducer,
    databaseService: databaseServiceReducer,

    //workflows
    workflows: workflowsReducer,
    workflow: workflowReducer,
    workflowsApiKeys: workflowsApiKeysReducer,
    workflowWallets: workflowWalletsReducer,
    workflowContracts: workflowsContractsReducer,

    //instances
    instances: instancesReducer,
    instance: instanceReducer,
    instancesSshKeys: instancesSshKeysReducer,
    instancesVolumes: instancesVolumesReducer,
    instancesVolume: instancesVolumeReducer,

    //notifications
    notifications: notificationsReducer,

    //unstoppable domains
    unstoppableDomains: unstoppableDomainsReducer,
  },
});
