import { Outlet } from "react-router-dom";
import {
  UnstoppableDomainsSearchUrl,
  UnstoppableDomainsUrl,
} from "../../utils/urls";

import { Helmet } from "react-helmet";
import UDomainsTab from "../../components/unstoppable-domain/UDomainsTab";

import { ReactComponent as GlobalIcon } from "./../../assets/icons/glob-alt.svg";
import { ReactComponent as SearchIcon } from "./../../assets/icons/search.svg";
import { Tabs, Typography, TabOptions } from "djuno-design";
import useServiceType from "../../hooks/useServiceType";
import UDomainsSearchTab from "../../components/unstoppable-domain/UDomainsSearchTab";

export const UnstoppableDomainsPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <GlobalIcon className="w-3" />
        Domains
      </div>
    ),
    url: UnstoppableDomainsUrl,
    element: <UDomainsTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <SearchIcon className="w-3" />
        Search
      </div>
    ),
    url: UnstoppableDomainsSearchUrl,
    element: <UDomainsSearchTab />,
    testId: "2th-tab",
  },
];

const UnstoppableDomainsPage = () => {
  const { serviceType } = useServiceType(
    process.env.REACT_APP_UNSTOPPABLE_DOMAINS_SERVICE_TYPE
  );
  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0">
            {serviceType?.ServiceName}
          </Typography.Title>
        </div>
        {/* <div className="mr-6 w-96"></div> */}
      </div>
      <div className="mx-6">
        <Tabs
          options={UnstoppableDomainsPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default UnstoppableDomainsPage;
