import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Helmet } from "react-helmet";
import { EmptyState, Typography, Flex, Switcher, Loading } from "djuno-design";
import {
  getNotificationsAsync,
  handleToggleNotifAudio,
  selectNotifications,
  selectNotificationsAudioAllowed,
  selectNotificationsLoading,
} from "../../../store/notifications/notificationsSlice";
import { NotificationToastItem } from "../../general/NotificationSocket";

const SettingsNotificationsTab = () => {
  const notifications = useAppSelector(selectNotifications);
  const notificationsLoading = useAppSelector(selectNotificationsLoading);
  const audioAllowed = useAppSelector(selectNotificationsAudioAllowed);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(getNotificationsAsync());
  // }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Notifications</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center">
        {/* <div className="flex items-center gap-2">
          <Typography.Text>Notifications</Typography.Text>
        </div> */}
        <div className="flex items-center gap-1 md:gap-2">
          <Typography.Text size="sm">notification sound</Typography.Text>
          <Switcher
            uiSize="small"
            value={audioAllowed}
            onChange={() => dispatch(handleToggleNotifAudio())}
          />
        </div>
      </div>
      <div className="mt-5 w-full">
        <Flex direction="col" className="max-w-lg mx-auto gap-3">
          {notificationsLoading && (
            <Flex items="center" justify="center" className="min-h-[300px]">
              <Loading borderSize={2} />
            </Flex>
          )}
          {!notificationsLoading &&
            notifications.map((notification, i) => (
              <NotificationToastItem key={i} notification={notification} />
            ))}
          {!notificationsLoading && notifications.length === 0 && (
            <EmptyState
              text="The notification inbox is empty"
              className="min-h-[300px]"
            />
          )}
        </Flex>
      </div>
    </>
  );
};

export default SettingsNotificationsTab;
