import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { NotificationsState, Notification } from "../../types/notifications";
import {
  getNotificationsApi,
  markAsReadNotificationApi,
} from "../../apis/notificationsAPI";
import { getLocalStorage, setLocalStorage } from "djuno-design";

const initialState: NotificationsState = {
  notifications: [],
  loading: false,
  audioAllowed: getLocalStorage("notif-sound", false),
};

export const getNotificationsAsync = createAsyncThunk<
  { notifications: Notification[] },
  undefined,
  IThunkRejectValue
>("notifications", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getNotificationsApi();
    const notifications = response.data.Result;

    return fulfillWithValue({ notifications });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const markAsReadNotificationAsync = createAsyncThunk<
  boolean,
  { notificationId: number },
  IThunkRejectValue
>(
  "notifications/mark-as-read",
  async ({ notificationId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      await markAsReadNotificationApi(notificationId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const addNotification = (notification: Notification) => {
  return {
    type: "notifications/addNotification",
    payload: notification,
  };
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload);
    },
    handleToggleNotifAudio: (state) => {
      setLocalStorage("notif-sound", !state.audioAllowed);
      state.audioAllowed = !state.audioAllowed;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationsAsync.fulfilled, (state, action) => {
        const { notifications } = action.payload;
        state.notifications = notifications;
        state.loading = false;
      })
      .addCase(getNotificationsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectNotifications = (state: RootState) =>
  state.notifications.notifications;
export const selectNotificationsLoading = (state: RootState) =>
  state.notifications.loading;

export const selectNotificationsAudioAllowed = (state: RootState) =>
  state.notifications.audioAllowed;

export const { handleToggleNotifAudio } = notificationsSlice.actions;
export default notificationsSlice.reducer;
